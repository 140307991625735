.cb-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  transition: all .2s ease;
  &-arrow {
    width: 2rem;
    height: 100%;
  }
  &-label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    &>span {
      font-size: calc((.4vw + .4vh + .2vmin) * 1.2);
      position: relative;
      text-transform: uppercase;
      margin-right: 2px;
      font-weight: 600;
      color: $c-white;
      @include respond(tv) {
        font-size: calc((.4vw + .4vh + .2vmin) * .9);

      }
      @include respond(phone) {
        font-size: 0;
        margin-right: 0;
        position: unset !important;
      }
      
    }
  }
  &-icon {
    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-container {
    height: 100%;
    width: calc(99vw / 100 * 4.3);
    position: relative;
    background: $c-control-bar-bg-l;

    @include respond(phone) {
      width: 4.5rem;
      height: 3rem;
    }
    @media only screen and (max-width: 900px) and (orientation: landscape) {
      width: 4.5rem;
      height: 3rem;
    }
    &--no-bg {
      background: transparent;
    }
    &--disabled {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        //background-color: rgba($color: #000000, $alpha: .6);
        z-index: 500;
      }
    }
  }
  &-content {

    position: absolute;
    bottom: calc(100% + 6px + #{$cb-gap});
    @include respond(phone-big) {
      bottom: calc(100% + 6px);
    }
    left: 0;
    //transform: translate(-50%, 0);
    background-color: $c-control-bar-bg;
    width: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    //z-index: 100;
    padding: 1.2rem 1.5rem;
    line-height: 16.5px;
    &--bottom {
      bottom: unset;
      left: unset;
      top: calc(100% + 8px);
    }
  }
}

.--bottom {
  bottom: -8px;
}

.vt-selector {
  width: 100%;
  height: 100%;
  padding: 5px;
  cursor: default;
  span {
    font-size: $fs-sm;
    line-height: 16px;
    color: $c-white;
  }
  &-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  &-option {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    span {
      text-align: center;
      font-size: $fs-sm;
      margin-left: 5px;
      color: $c-white;
    }
  }
}


.immersive-icon {
  
  width: 100%;
  height: 100%;
  position: relative;
  transition: all .2s ease;
  background-image: url("/images/player/deep-logo-without-colors.svg");
  background-repeat: no-repeat !important;
  background-size: 70% 60%;
  background-position: 50% 50%;

  @include respond(phone) {
    background-size: 80% 60%;
  }
  &--active {
    background-image: url("/images/player/deep-logo.svg");
    background-repeat: no-repeat !important;
    background-size: 80% 70%;
    background-position: 50% 50%;
    
  }
  &-prev {
    background-image: url("/images/player/backward.svg");
    background-position: center;
    background-size: 50% 45%;
    background-repeat: no-repeat;
    object-fit: contain;
    width: 100%;
    height: 100%;
    transition: all .2s ease;
  }
  &-next {
    background-image: url("/images/player/forward.svg");
    background-position: center;
    background-size: 50% 45%;
    background-repeat: no-repeat;
    object-fit: contain;
    width: 100%;
    height: 100%;
    transition: all .2s ease;
  }
}