.menu-btn {
  position: relative;
  height: 100%;
  transition: all .2s ease;
  width: 100%;
  @include buttonHoverEffect();
  //@include buttonActiveEffect();
  &--simple {
    @include buttonHoverEffect();
    .menu-btn-items-list {
      justify-content: center;
    }
    .menu-btn-items-list-wrapper {
      table {
        width: 70%;
        display: flex;
        justify-content: center;
        tbody {
          width: 100%;
          tr {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 0;
          }
        }
        td {
          text-align: start;
          display: flex;
          align-items: center;
          &:first-child {
            width: 20% !important;
          }
          &:last-child {
            width: 80% !important;
          }
        }
      }
    }
  }
  &-label {
    width: 100%;
    height: 2rem;
    background-color: $c-control-bar-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .3rem 0;
    padding-top: .8rem;
    span {
      color: #ccc;
      font-style: $fs-sm;
    }
  }
  &-back {
    width: 100%;
    height: 5rem;
    background-color: $c-control-bar-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .3rem;
    position: relative;
    span {
      position: relative;
      font-size: 1.4rem;
      text-transform: lowercase;
      font-weight: bold;
      &::before {
        content: ' ';
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        left: -1rem;
        top: 50%;
        transform: translate(-50%, -50%);
        border-right: 4px solid #fff;
        border-left: 4px solid transparent;   
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        transition: all .2s ease; 
      }
    }
  }
  &-icon {

    &-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer
    }
  }
  &-items-list {

    &-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: $c-control-bar-bg;
      /*
      @include respond(phone-big) {
        overflow-y: auto;
        @include scrollbar();
      }
      */
      overflow-y: auto;
      @include scrollbar();
      table {
        table-layout: fixed;
        width: 70%;
        margin: 0 auto;
        tr {
          //width: 8rem !important;
          margin: 0 auto;
          overflow: hidden;

        }
        td {
          width: 40%;
          padding: .55rem 0;
          text-align: start;
          &:first-child {
            width: 20%;
          }
          &:nth-child(2) {
            width: 50%;
          }
        }
      }
    }
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    position: absolute;
    height: 0;
    opacity: 0;
    visibility: hidden;
    bottom: calc(100% + #{$cb-gap});
    left: 50%;
    transform: translate(-50%, 0);
    //height: auto;
    justify-content: flex-start;
    align-items: center;
    width: 120px;
    flex-direction: column;
    //z-index: 350;
  }
  &-item {
    cursor: pointer;
    transition: all .3s ease;
    //padding: 5px 0;
    position: relative;
    
    &:hover {
      //background-color: lighten($color: $c-control-bar-bg, $amount: 20%);
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &-value {
      font-weight: bold;
      color: $c-white;
      //margin: 0 7px;
    }
    &-label {
      color: $c-white;
    }
  }
}

.--closed {
  display: none;
}

.--opened-menu>.menu-btn-items-list {
  animation-name: menu-animation;
  @include respond(phone) {
    animation-name: m-menu-animation;
  }
  animation-timing-function: ease-out;
  animation-duration: .2s;
  animation-fill-mode: forwards;
}

.--mm-opened {
  animation-name: menu-animation;
  @include respond(phone) {
    animation-name: m-menu-animation;
  }
  animation-timing-function: ease-out;
  animation-duration: .2s;
  animation-fill-mode: forwards;
}

.--mm-closed {
  animation-name: menu-animation-reversed;
  @include respond(phone) {
    animation-name: m-menu-animation-reversed;
  }
  animation-timing-function: ease-out;
  animation-duration: .2s;
  animation-fill-mode: forwards;
}

.menu-btn--simple.--opened-menu>.menu-btn-items-list {
  animation-name: sm-animation;
  @include respond(phone) {
    animation-name: sm-animation;
  }
  animation-timing-function: ease-out;
  animation-duration: .2s;
  animation-fill-mode: forwards;
}
.menu-btn--simple.--closed-menu>.menu-btn-items-list {
  animation-name: sm-animation-reversed;
  @include respond(phone) {
    animation-name: sm-animation-reversed;
  }
  animation-timing-function: ease-out;
  animation-duration: .2s;
  animation-fill-mode: forwards;
}

.--not-hoverable {
  &:hover {
    background-color: transparent !important;
  }
}

.--closed-menu>.menu-btn-items-list {
  animation-name: menu-animation-reversed;
  @include respond(phone) {
    animation-name: m-menu-animation-reversed;
  }
  animation-timing-function: ease-out;
  animation-duration: .2s;
  animation-fill-mode: forwards;
}

.menu-btn--simple.--opened-menu>.subs-menu.menu-btn-items-list {
  animation-name: sm-animation-subs !important;
  animation-timing-function: ease-out;
  animation-duration: .2s;
  animation-fill-mode: forwards;
}

@keyframes sm-animation-subs {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  70% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    height: 100px;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes sm-animation-subs-reversed {
  0% {
    height: 70px;
    opacity: 1;
    visibility: visible;
  }
  70% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes menu-animation-reversed {
  0% {
    height: 190px;
    opacity: 1;
    visibility: visible;
  }
  70% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes menu-animation {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  70% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    height: 190px;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes m-menu-animation-reversed {
  0% {
    height: 150px;
    opacity: 1;
    visibility: visible;
  }
  70% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes m-menu-animation {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  70% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    height: 150px;
    opacity: 1;
    visibility: visible;
    //overflow-x: visible;
    //overflow-y: auto;
  }
}

@keyframes sm-animation {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  70% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    height: 85px;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes sm-animation-reversed {
  0% {
    height: 85px;
    opacity: 1;
    visibility: visible;
  }
  70% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}


.mm {
  &_content {
    //background-color: $c-control-bar-bg;
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: end !important;
    & > div {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &_item {
      width: 100%;
      height: 3rem;
      background-color: $c-control-bar-bg;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}

@keyframes m-sub-menu {
  0% {
    
    right: -100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
    height: 0;
    
  }
  100% {
    right: 110%;
    height: 100px;
    opacity: 1;
    bottom: 0;
    visibility: visible;
    overflow-y: auto;
  }
}

@keyframes m-sub-menu-reversed {
  0% {
    right: 110%;
    height: 100px;
    opacity: 1;
    bottom: 0;
    visibility: visible;
  }
  100% {
    right: -100%;
    bottom: -10%;
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
}

@keyframes diagonal-move-anim {

	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(150px, 150px);
	}

}

@keyframes mff {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.--opened-menu-sub > .menu-btn-items-list {
  transform: unset;
  left: unset;
  top: unset;
  bottom: 0;
  animation-name: m-sub-menu;
  animation-timing-function: ease-out;
  animation-duration: .2s;
  animation-fill-mode: forwards;
}

.--closed-menu-sub > .menu-btn-items-list {
  animation-name: mff;
  animation-timing-function: linear;
  animation-duration: 10s;
  animation-fill-mode: forwards;
}
