$c-control-bar-bg: rgba(#000, .6);
$c-control-bar-bg-l: rgba(#000, .6);
$c-timeline-bg: #a0a0a0;
$c-control-bar-menu-bg: #212121;
$c-white: #fff;
$c-error: #d32f2f;

$progress-control-height: 24px;

$fs-sm: 1.2rem;
$fs-md: 1.6rem;
$fs-bg: 3.2rem;
$ff: 'Montserrat', sans-serif;

$cb-gap: 6px;