.tooltip {
  position: absolute;
  bottom: calc(100% + #{$cb-gap});
  left: 50%;
  //transform: translate(-50%, -50%);
  background-color: $c-control-bar-bg;
  display: flex;
  justify-content: center;
  width: 14rem;
  align-items: center;
  padding: 1.2rem 1.5rem;
  line-height: 16.5px;
  z-index: 5;
  &--bottom {
    bottom: unset;
    top: calc(100% + 6px);
    right: 0;
    left: unset !important;
    transform: unset !important;
  }
  &--bottom-right {
    position: absolute;
    bottom: calc(100% + 6px + #{$cb-gap});
    left: 0;
    transform: unset !important;
    width: 18rem;
  }
  &--top {
    transform: translate(-50%, 0);
  }
  span {
    font-size: $fs-sm;
    color: $c-white;
  }
}
