@import '../../styles/vars';
@import '../../styles/mixins';

.cb {
  position: absolute;
  bottom: 6px;
  width: 100%;
  height: 5.8%;
  z-index: 9999;
  transition: all .2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond (phone-big) {
    height: 3rem;
  }
  @include respond(tv) {
    height: 5.2%;
  }
  &_content {
    height: 100%;
    width: calc(100% - 12px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all .3s ease;
    @include respond (phone-big) {
      height: 3rem;
    }
  }
  &_item {
    background: $c-control-bar-bg;
    width: 4.3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include respond(phone-big) {
      width: 45px;
    }
    &:not(:last-child) {
      margin-right: 6px;
    }
    //progress-control
    &:nth-child(3) {
      width: 63%;
      padding-right: 5px;
      //padding-left: 10px;
      & > div {
        height: 55%;
        padding: 8px;
        padding-left: calc(8px + 5px);
        padding-left: 0;
        box-sizing: content-box;
        @include respond(phone-big) {
          height: 20px;
        }
      }
      max-width: 99%;
      flex: 1;
      /*
      @include respond(tv) {
        max-width: 85%;
      }
      @include respond(desktop) {
        max-width: 75%;
      }
      @include respond(tab-land) {
        max-width: 70%;
      }
      @include respond(tablet) {
        max-width: 65%;
      }
      @include respond(phone-big){
        max-width: 63%;
      }
      @include respond(phone) {
        max-width: 70%;
      }
      @media only screen and (max-width: 1150px) and (orientation: landscape) {
        max-width: 99%;
      }
      */
    }
  }
}