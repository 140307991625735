.margin-right-bg {
  margin-right: 10px;
}

.button-big {
  width: 6.5rem;
  @include respond(phone) {
    width: 9rem;

  }
  svg {
    width: 100%;
    height: 100%;
  }
  svg {
    width: 90%;
    height: 90%;
  }
}
.u-wm {
  width: 25px;
}
.u-wu {
  width: 80px;
}
.u-pl-tv {
  @include respond(tv) {
    padding-left: 10px;
  }
}
.u-fs {
  background-image: url('/images/player/fullscreen.svg');
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  //object-fit: cover;
  width: 100%;
  display: block;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-size: 30%;
  @include respond(phone-big) {
    background-size: 34%;
  }
}
.u-fs-out {
  background-image: url('/images/player/fullscreen-off.svg');
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  width: 100%;
  display: block;
  height: 100%;
  @include respond(phone-big) {
    background-size: 34%;
  }
}

.no-bg {
  background: transparent !important;
}

.btn-test {
  font-size: 2rem !important;
}

.--btn-disabled {
  position: relative;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba($color: #000000, $alpha: .6);
  }
}

.box {
  background-color: #000000;
  position: relative;
  overflow-y: hidden;
}

.video-bg {
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 301;
}

.--hidden {
  opacity: 0 !important;
}

.slider-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  //background-color: red;
  z-index: 301;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    .time-tooltip-marker {
      opacity: 1;
      visibility: visible;
    }
  }
}

.cb-buttons {
  //width: 150px;
  display: flex;
  z-index: inherit;
}

.u-hide {
  visibility: hidden !important;
  opacity: 0 !important;
}

.u-rcp {
  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 100px;
    //background-color: #000;
    @include respond(phone-big) {
      height: 50px;
    }
  }
}

.u-rcp-top {
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100px;
    @include respond(phone-big) {
      height: 50px;
    }
    //background-color: #000;
  }
}

.u-on-top-1 {
  animation-name: on-t-1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-duration: .3s;
  @keyframes on-t-1 {
    0% {
      top: 0;
    }
    100% {
      top: -8rem;
    }
  }}

.u-on-top-1-r {
    animation-name: on-t-r-1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-duration: .3s;
    @keyframes on-t-r-1 {
      0% {
        top: -8rem;
      }
      100% {
        top: 0;
      }
}}

.u-on-top {
  animation-name: on-t;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-duration: .3s;
  @keyframes on-t {
    0% {
      bottom: -8rem;
    }
    100% {
      bottom: 6px;
    }
  }}

.u-on-bottom {
  animation-name: on-b;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-duration: .3s;
  @keyframes on-b {
    0% {
      bottom: 6px;
    }
    100% {
      bottom: -8rem;
    }
  }
}

.u-hidden {
  display: none !important;
}

.box {
  overflow: hidden !important;
  @include respond(phone-big) {
    position: absolute;
    top: 20%;
    left: 0;
    height: 30vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1150px) and (orientation: landscape) {
      height: 80vh;
    }
  }
}
.u-body {
  height: 100vh;
}
.btn-arrow {
  width: 100%;
  height: 100%;
  position: relative;
  &::before {
    content: ' ';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;   
    border-bottom: 4px solid #fff;
    transition: all .2s ease; 
  }
  &--opened {
    &::before {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
      left: calc(50% - 4px)
    }
  }
}

.btn-effects {
  @include buttonActiveEffect();
  @include buttonHoverEffect();
}

.margin-left-min {
  margin-left: .5rem;
}

.hover-area {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 300px;
  background: red;
}

video {
  object-fit: fill;
}
.norm {
  bottom: 0 !important;
}

.safari-only {
  .vjs-control {
    //width: 4.5rem !important;
  }
}

.subtitles {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &_box {
    display: inline-block;
    background:rgba($color: #000000, $alpha: .4);
    padding: 5px;
  }
  p {
    font-size: 26px;
    color: #fff;
  }
  &_container {
    position: absolute;
    bottom: 8rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 3rem;

    z-index: 500;
  }
}

.vjs-control-bar {
  opacity: 1;
  visibility: visible;
}

.message {
  h1 {
    font-size: $fs-md;
    color: #fff;
    font-weight: 400;
    @include respond(phone-big) {
      font-size: calc(#{$fs-md} - 6px);
    }
  }
  margin-bottom: 1rem;
  &_wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, .2);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  &_not-supported {
    width: 30rem;
    min-height: 20rem;
    background-color: rgba($color: #E55137, $alpha: .6);
    padding: 2rem;
    @include respond(phone-big) {
      width: 95%;
      min-height: 60%;
      max-height: 95%;
    }
  }
  &_container {
    &:not(:last-child) {
      margin-bottom: 2rem;
      @include respond(phone-big) {
        margin-bottom: 1rem;
      }
    }
  }
  &_additional-info {
    p {
      font-size: $fs-md;
      color: #fff;
      font-weight: 900;
      @include respond(phone-big) {
        font-size: calc(#{$fs-md} - 4px);
      }
     }
  }
}

.trans-box {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
  position: relative;
  background-color: #424242;
  span {
    color: #fff;
    font-size: 19px;
  }
  h5 {
    position: absolute;
    bottom: calc(100% + 30px);
    left: 50%;
    color: #fff;
    font-size: 21px;
    transform: translate(-50%, 0);
  }
}

.dashboard-box {
  width: 120px;
  height: 180px;
  background: #424242;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 10px;
  margin: 0 auto;
  span {
    color: #fff;
    font-size: 14px;
  }
  span:last-child {
    margin-bottom: 15px;
  }
}
.box {
  //padding-top: 50px;
  display: flex;
  .sec {
    width: 50%;
  }
}

.gen_box {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  :first-child {
    height: 30px;
    margin-bottom: 20px;
  }
}

.u-mouse {
  &-top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 30%;
    //background: red;
  }
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
}
/*
.vjs-tech {
  pointer-events: none;
}
*/

#pulse_icon {
  animation: r 2s linear infinite;
  //background-color: red !important;
}

#play_icon_pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

&::after {
    content: '';
    position: absolute;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid #e0e0e0;
    //border-radius: 50%;
    //border-top-left-radius: 50%;
    //border-top-right-radius: 50%;
    animation: pulse 2s linear infinite;
}

}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(#e0e0e0, .3);
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(#e0e0e0, .3);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(#e0e0e0, .3);
	}
}


@keyframes r {
  0% {
    -webkit-transform: scale(0.9)
  }
  100% {
    -webkit-transform: scale(1);
  }
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid black;
}



.triangle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 60000ms linear infinite;
  
  &_outer {
    position: absolute;
    width: 55px;
    height: 55px;
    // background: #333;
    
    &::before {
      content: '';
      position: absolute;
      width: 40%;
      height: 100%;
      background: #ccc;
      animation: slide 1500ms linear infinite;
      // filter: blur(20px);
    }
  }

  &_inner {
    width: 53px;
    height: 53px;
    //background: #000;
    transform: translateY(2px);
    
    &::before {
      // content: '';
      position: absolute;
      width: 20%;
      height: 100%;
      background: #ccc;
      animation: slide 1500ms -118ms linear infinite;
      // filter: blur(10px) contrast(5);
    }
  }
  
  .clip {
    clip-path: polygon(
      0 100%,
      100% 100%,
      50% 0,
    );
  }
}



@keyframes slide {
  0% {
    transform: translateX(-150px);
  }
  
  100% {
    transform: translateX(300px);
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes rotateX {
  0% {
    transform: rotateX(0deg);
  }
  
  100% {
    transform: rotateX(360deg);
  }
}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }
  
  100% {
    transform: rotateY(360deg);
  }
}


.gradient-border {
  --border-width: 2px;

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Lato, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: white;
  //background: #222;
  //border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    //border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.def_icon {
  width: 50%;
  height: 50%;
  @include respond(phone-big) {
    transform: scale(0.8);
  }
}
/*
.vjs-fullscreen {
  @media screen and (orientation:landscape) {
    #cb-id > div {
      &:nth-child(3) {
        display: none;
        width: 99% !important;
        max-width: 99% !important;

      }
    }
  }
}
*/

.u-volume {
  @include respond(desktop) {
    //display: none;
  }
}

.u-dont-highlight {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vjs-text-track-cue {
  font-family: $ff !important;
  div {
    font-family: $ff !important;
  }
}

.vjs-text-track-display {
  font-family: $ff !important;
  div {
    font-family: $ff !important;
  }
}