.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  &-view {
    width: 50rem;
    height: 20rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    background: rgba(0, 0, 0, 0.75);
    @include respond(phone) {
      width: 70%;
      height: 70%;
    }
  }
  &-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 4rem;
    span {
      color: #fff;
      font-size: $fs-md;
    }
  }
  &-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &-btn {
    background: #fff;
    color: #000;
    text-align: center;
    width: 15rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    line-height: 60px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-weight: 600;
    position: relative;
    @include respond(phone-big) {
      font-size: 16px;
    }
    .tooltip {
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease;
    }
    &:disabled {
      //background-color: rgba(#fff, .5);
    }
    &:hover {
      .tooltip {
        opacity: 1;
        visibility: visible;
        span {
          color: white;
        }
      }
    }
    @include respond(phone) {
      width: 10rem;
      font-size: 17px;
    }
    &:not(:last-child) {
      margin-right: 5rem;
      @include respond(phone) {
        margin-right: 3rem;
      }
    }
  }
}