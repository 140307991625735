@import '../vars';
@import '../mixins';
.icon {
  &-subtitles {
    @include controlBarBtn();
    width: 100%;
    @include respond(phone-big) {
      font-size: 13px;
    }
    span {

    }
    &--selected {
      //border: 1px solid orangered;
    }
  }
  &-language {
    @include controlBarBtn();
    color: $c-white;
    font-size: calc((.4vw + .4vh + .2vmin) * 1.6);
    @include respond(phone-big) {
      font-size: 13px;
    }
    span {
      color: $c-white;
    }
  }
  &-quality {
    @include controlBarBtn();
    &::before {
      background-image: url('/images/player/quality.svg');
      width: 50%;
      height: 50%;
      @include respond(phone-big) {
        width: 2rem;
      }
    }
  }
}

.vjs-play-control.vjs-control.vjs-button .vjs-icon-placeholder {
  @include controlBarBtn();
  @include buttonHoverEffect();
  @include buttonActiveEffect();
  &::before {
    background-image: url('/images/player/play.svg');
  }
}


.vjs-playing.vjs-play-control.vjs-control.vjs-button .vjs-icon-placeholder {
  @include controlBarBtn();
  &::before {
    background-image: url('/images/player/pause.svg');
    background-size: 80% 80%;
    background-position-y: calc(50% + 1px);
  }
}

.vjs-mute-control.vjs-control.vjs-button.vjs-vol-3 .vjs-icon-placeholder {
  transition: all .2s ease;
  @include controlBarBtn();
  @include buttonHoverEffect();
  @include buttonActiveEffect();
  &::before {
    background-image: url('/images/player/volume.svg');
  }
}

.vjs-mute-control.vjs-control.vjs-button.vjs-vol-0 .vjs-icon-placeholder {
  transition: all .2s ease;
  @include controlBarBtn();
  @include buttonHoverEffect();
  @include buttonActiveEffect();
  &::before {
    background-image: url('/images/player/volume-off.svg');
  }
}
.vjs-mute-control.vjs-control.vjs-button.vjs-vol-2 .vjs-icon-placeholder {
  transition: all .2s ease;
  @include controlBarBtn();
  @include buttonHoverEffect();
  @include buttonActiveEffect();
  &::before {
    background-image: url('/images/player/volume-middle.svg');
  }
}
.vjs-mute-control.vjs-control.vjs-button.vjs-vol-1 .vjs-icon-placeholder {
  transition: all .2s ease;
  @include controlBarBtn();
  @include buttonHoverEffect();
  @include buttonActiveEffect();
  &::before {
    background-image: url('/images/player/volume-middle.svg');
  }
}

.icon-settings {
  @include controlBarBtn();
  @include buttonHoverEffect();
  //@include buttonActiveEffect();
  &::before {
    background-image: url('/images/player/settings.svg');
    @include respond(phone-big) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.vjs-fullscreen-control.vjs-control.vjs-button .vjs-icon-placeholder {
  @include controlBarBtn();
  @include buttonHoverEffect();
  @include buttonActiveEffect();
  transition: all .2s ease;
  &::before {
    background-image: url('/images/player/fullscreen.svg');
    background-size: 15%;
    background-position-y: calc(50% + 0.5px);
    background-position-x: calc(50% + 0.5px);
  }
}


@keyframes icon-popout {
  from {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
  }

  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes icon-popout {
  from {
    -webkit-transform: scale(0.1);
  }

  80% {
    -webkit-transform: scale(1.1);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@keyframes icon-hide {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  to {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes icon-hide {
  from {
    -webkit-transform: scale(1);
  }

  to {
    -webkit-transform: scale(0);
  }
}