.view-type-menu {
  width: 100%;
  height: 100%;
  //background-color: $c-control-bar-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  &-options {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 90%;
    flex-direction: column;
  }
  &-option {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    background: #fff;
    color: #000;
    position: relative;
    &--active {
      background: #E90030E5;
      color: #fff;
      &::before {
        content: '\00d7';
        display: inline-block;
        top: 50%;
        left: 10%;
        position: absolute;
        transform: translate(-50%, -50%);
        
        font-size: 25px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    span {
      font-size: calc(#{$fs-md} - 3px);
      font-weight: bold;
    }
  }
}