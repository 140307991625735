.checkbox {
  width: 9px;
  height: 9px;
  &-line {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid white;
    position: relative;
  }
  &-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background: white;
    border-radius: 50%;
  }
}