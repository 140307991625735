@import '../../../styles/vars';
@import '../../../styles/mixins';

.button {
  width: 100%;
  //min-width: 40px;
  height: 100%;
  //min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: unset;
  @include buttonHoverEffect();
  cursor: pointer;
}