html {
  font-size: 10px;
  font-family: $ff;
  overflow-x: hidden !important;
  -webkit-text-size-adjust: none !important;
}

span, button, p, h1, h2, h3, h4, h5 {
  font-family: $ff;
}

button {
  background: unset;
  border: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none;
          user-select: none;

}