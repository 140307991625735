@mixin moovieTitle {
    font-family: $ff;
    line-height: 4.8rem;
    font-size: $fs-bg;
    font-style: normal;
    font-weight: 700;
    color: $c-white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75), 0px 0px 8px rgba(0, 0, 0, 0.5);
}

@mixin scrollbar {
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          
          background: #444;
          box-shadow: 0 0 1px 1px rgb(3, 2, 2), inset 0 0 4px rgba(0,0,0,0.3);
        }
        &::-webkit-scrollbar-thumb {
          
          background: linear-gradient(left, #3e3e3e, #111, #000);
          box-shadow: inset 0 0 1px 1px #646464;
        }
        scrollbar-width: thin;
}

@mixin controlBarBtn {
    width: 4rem;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: '';
        position: absolute;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%);
        width: 2rem;
        height: 2rem;
        background-size: 100% 100%;
        //background-position: 50% calc(50% - 1px);
        background-repeat: no-repeat;
    }
    span {
        font-size: 14px;
        font-weight: 500;
      }
}

@mixin buttonHoverEffect {
    &:hover {
        background-color: darken($color: $c-control-bar-bg, $amount: 10%) !important;
    }
}

@mixin buttonActiveEffect {
    &:active,
    &:visited {
        transform: scale(0.9);
        background-color: darken($color: $c-control-bar-bg, $amount: 20%) !important;
    }
}

@mixin respond($breakpoint) {
    @if $breakpoint == phone-min {
        @media ( max-width: 25em ) { @content }; //400px
    }
    @if $breakpoint == phone {
        @media (max-width: 43.75em) { @content }; //700px
    }
    @if $breakpoint == phone-big {
        @media(max-width: 58em) { @content }; //900px
    }
    @if $breakpoint == tablet {
        @media (max-width: 69em) { @content }; //1100px
    }
    @if $breakpoint == tab-land {
        @media (max-width: 82em) { @content }; //1300px
    }
    @if $breakpoint == desktop {
        @media (max-width: 112.5em) { @content }; //1800px
    }
    @if $breakpoint == tv {
        @media (min-width: 113em) { @content }
    }
  }

