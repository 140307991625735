.vjs-skin {
  .vjs-subs-caps-button {
    display: none !important;
  }
  .vjs-control-bar {
    background-color: transparent !important;
    height: 4rem;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 5px;
    position: absolute;
    opacity: 1 !important;
    visibility: visible;
    transition: all .3s ease-in-out !important;
    @include respond(phone) {
      margin-bottom: 0;
    }    
    
    //padding: 10px 8px;
    &-icon {
      &-container {
        //width: 60px;
        //background-color: $c-control-bar-bg;
      }
    }
  }
  .vjs-button {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    //padding-top: 5px;
    //line-height: .5px !important;
    @include respond(phone) {
      font-size: 1.5rem;
    }

  }
  .vjs-control {
    cursor: pointer;
    width: 5rem;
    height: 100%;
    &:hover {
      text-shadow: none !important;
    }
  }
  .custom-controlbar {
    height: 40px;
    padding: 8px;
    @include respond(phone) {
      padding: 8px 2px;
    }
  }
  .vjs-volume-panel.vjs-control.vjs-volume-panel-vertical {
    height: 100%;
    @include respond(phone) {
      display: none !important;
    }
  }
  .vjs-volume-level {
    width: 2.2rem;
    height: 12rem;
    //background: rgba(0, 0, 0, 0.75);
    
    background-color: rgba(161,161,160,255);
    &::before {
      display: none;
    }
  }
  .vjs-volume-bar.vjs-slider-vertical {
    width: 2.2rem;
    height: 12rem;
    overflow-y: hidden !important;

    background-color: rgba(67,67,66,255);
  }
  .vjs-volume-panel.vjs-volume-panel-vertical {
    width: 5rem !important;
  }
  .vjs-volume-control.vjs-control.vjs-volume-vertical {
    background-color: $c-control-bar-bg;
    left: 0 !important;
    right: unset !important;
    position: absolute !important;
    top: unset !important;
    bottom: calc(100% + #{$cb-gap}) !important;
    height: 14rem;
    width: 100%;
  }
  .vjs-hover {
    
  }
    
}
.controlbar-top {
  top: 6px;
  //test
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all .2s ease-in-out;
  height: 6.2%;
  z-index: 9999;
  width: calc(100% - 12px);
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, 0);
  @include respond(tv) {
    height: 5.5%;
  }
  &_content {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  &-section {
    height: 100%;
    width: 50%;
    & > span {
      @include moovieTitle();
      @include respond(phone-big) {
        font-size: 12px;
      }
    }

    @include respond(phone-big) {
      &:first-child {
        width: 70%;
      }
      &:last-child {
        width: 30%;
      }
    }
  }
  &-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    &:last-child {
      justify-content: flex-end;
    }
  }
}
.time-tooltip {
  &-container {
    position: absolute;
    top: -60px;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: all .1s ease-in-out;
    display: none;
  }
  &-marker {
    position: absolute;
    //transform: translate(-50%, -50%);
    bottom: 0;
    width: 1px;
    background-color: #fff;
    height: $progress-control-height;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s ease;
    &::before {
      content: ' ';
      position: absolute;
      right: 100%;
      width: 25px;
      height: 100%;
      background: linear-gradient(90deg,hsla(0,0%,100%,0),#fff);
    
    }
  }
}

.control-custom > div:nth-child(3) {
  flex: 1;
  padding: 8px 1rem;
  height: 100%;
  background-color: $c-control-bar-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  @include respond(tv) {
    max-width: 85%;
  }
  @include respond(desktop) {
    max-width: 75%;
  }
  @include respond(tab-land) {
    max-width: 70%;
  }
  @include respond(tablet) {
    max-width: 65%;
  }
  @include respond(phone-big){
    max-width: 63%;
  }
  @include respond(phone) {
    max-width: 70%;
  }
 }


 .control-custom > div:nth-child(4) {
  height: 100%;
}

.control-custom > div:nth-child(5) {
  height: 100%;
}

.control-custom > div:nth-child(6) {
  height: 100%;
}

.vjs-icon-placeholder {
  //font-size: 1.8rem;
  width: 100% !important;
  &::before {
    position: absolute;
    top: 64% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

.time-display {
  position: absolute;
  top: 50%;
  left: 4rem;
  width: 12rem;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300;
  span {
    font-size: 1.1rem;
    font-weight: bold;
    color: #424242;
  }
}

.players_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-js .vjs-tech {
  background: transparent !important;
  //display: none !important;
  //object-fit: unset;
}
.video-js {
  background-color: transparent !important;
}

.slider-tracks {
  div {
    transition: width .1s ease-in-out;
  }
}

.rail {
  &:hover {
    
  }
}

.slider-wrapper {
  &:hover {
    &::before {
      content: ' ';
      position: absolute;
      left: calc(var(--x) - 30px);
      top: 0;
      width: 30px;
      height: 100%;
      background: linear-gradient(90deg,hsla(0,0%,100%,0),#fff);
    }
  }
}

/* Switch to the exit icon when the player is in fullscreen */
.vjs-fullscreen .vjs-fullscreen-control.vjs-control.vjs-button .vjs-icon-placeholder {
  &::before {
    background-image: url('/images/player/fullscreen-off.svg') !important;
    background-position-y: calc(50% + 0.5px);
  }
}
.vjs-mouse-display {
  background-color: unset !important;
  &::before {
    opacity: 0;
    visibility: hidden;
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(to top,hsla(0,0%,100%,0),#fff);
  }
}
.vjs-volume-tooltip {
  display: none !important;
}

.vjs-volume-bar.vjs-slider-bar.vjs-slider.vjs-slider-vertical {
  &:hover {
    .vjs-mouse-display {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.control-custom {
  //bottom: -300px !important;
  //transition: all .5s ease-in-out !important;
  &:hover {
    bottom: 0 !important;
    .menu-btn {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
}

.u-mouse {
  &-top {
    &:hover {
    }
  }
  &:hover {
    ~ .control-custom {
      bottom: 0 !important;
      .menu-btn {

        opacity: 1 !important;
        visibility: visible !important;
      }
    }
    ~ .menu-btn {
    }
  }
}

.controlbar-top {
  //top: -200px;
  //z-index: 600;
  &-section {
    &:first-child {
      padding-left: 10px;
    }
  }
}

.video-js .vjs-captions {
  //font-family: 'tahoma';
  font-size: 16px;
  
}

.video-js .vjs-text-track {
  background-color: transparent;
  color: red !important;
}

.vjs-text-track-cue.vjs-text-track-cue-en {
  font-size: 35px !important;
  @include respond(phone-big) {
    font-size: 15px !important;
  }
}

.vjs-text-track-display {
  @include respond(phone-big) {
    //bottom: 10rem !important;
  }
}

.vjs-text-track-cue {
  font-family: $ff !important;
  font-size: 35px !important;
  //-webkit-text-stroke: 2px black;
  //text-shadow: 5px 5px 7px black !important;
  text-shadow: 1px 1px 10px black, 0 0 10px black, 0 0 10px black;

  @include respond(phone-big) {
    font-size: 15px !important;
    //inset: 180px 0 0 0 !important;
    
  }
  
  & > div {
    background-color: transparent !important;
  }
}

.message_additional-info {
  display: flex;
  justify-content: space-between;
  &_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    & > span {
      font-size: 1.5rem;
      color: $c-white;
    }
  }
}

.vjs-fullscreen .vjs-tech {
  object-fit: contain;
}

.volume-range-slider-container {
  overflow: hidden;
  cursor: pointer;
  &:hover {
    &::before {
      content: ' ';
      position: absolute;
      top: calc(var(--y));
      left: 0;
      width: 100%;
      height: 30px;
      background: linear-gradient(0,hsla(0,0%,100%,0),#fff);
      z-index: 9999;
    }
  }
}