
.loading-spinner {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  z-index: 100;
  display: none;
}

.loading-spinner svg {
  display: block;
  margin: 0 auto;
}

.loading-spinner svg .st0 {
  fill: #DD375B;
}

.loading-spinner svg .st1 {
  fill: #3A7CDB;
}

.loading-spinner svg .st0_white,
.loading-spinner svg .st1_white {
  fill: #fff;
}

.loading-spinner svg .st2 {
  fill: #482560;
}

.loading-spinner svg .st3 {
  -webkit-clip-path: url(#SVGID_6_);
          clip-path: url(#SVGID_6_);
}

.loading-spinner svg .st4 {
  fill: #FFFFFF;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 16px;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.75);
}

.custom-waiting .loading-spinner {
  display: block;
  z-index: 800;
}
.vjs-loading-spinner{display:none!important;}