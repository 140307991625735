@import '../../styles/vars';

.v {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover > &_content_wrapper {
    opacity: 1;
    visibility: visible;
  }
  &_content {
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 100%;
    height: 95%;
    background-color: $c-control-bar-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    &_wrapper {
      &:hover > &_content {
        opacity: 1;
        visibility: visible;
      }
      bottom: 100%;
      left: 0;
      //transform: translate(-50%, -50%);
      position: absolute;
      opacity: 0;
      visibility: hidden;
      &:hover {
        opacity: 1;
        visibility: visible;
      }
      display: flex;
      align-items: center;
      padding: 5px 0;
      width: 100%;
    }


  }
  &_on {
    height: 55%;
    width: 50%;
  }
  &_off {
    height: 55%;
    width: 50%;
  }
  &_middle {
    height: 55%;
    width: 50%;
  }
  &_input {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* Chromium */
    width: 60px;
    background: red;
    &::-webkit-slider-thumb {
      opacity: 0;
    }
    &::-webkit-slider-runnable-track {
      background: rgba(red, .7);
    }
    &::-moz-range-track {
      background-color: red;
    }
  }
}