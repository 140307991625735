* {
  padding: 0;
  margin: 0;
}

.video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.--hidden {
  display: none;
}

.--active {
  display: block;
}

.control-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #424242;
  color: #fff;
  text-align: center;
  flex-grow: 1;
  font-size: 1.8rem;
}

.control-container {
  display: flex;
  min-height: 20vh;
}

.control-view {
  width: 30%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 30%;
  width: 60%;
  color: #fff;
}

.box {
  height: 80vh;
  position: relative;
}
  .confirmation-message {
    background: rgba(0, 0, 0, 0.75);
    width: 60%;
    height: 40%;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    z-index: 302;
    color: #fff;
    font-family: 'Montserrat';
    padding: 52.999998px 90px 60px 90px;
    text-align: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: none;
    z-index: 101;
  }

  .confirmation-message .msg {
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 36px;
  }

  .confirmation-message .btn {
    background: #fff;
    color: #000;
    text-align: center;
    width: 180px;
    height: 60px;
    margin-right: 60px;
    cursor: pointer;
    font-size: 24px;
    line-height: 60px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-weight: 600;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .confirmation-message .btn:last-child {
    margin-right: 0;
  }

  .confirmation-message.enabled {
    display: flex;
  }

.control-btn {
  padding: 10px;
}

/*
.video-js .vjs-control-bar {
  display: block !important;
}
.vjs-live-control vjs-control {
  display: block !important;
}

.vjs-current-time vjs-time-control vjs-control {
  display: block !important;
}
*/

.vjs-control-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  width: 90%;
  margin: 0 auto;
}


.custom-controlbar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
}

.time-tooltip {
  display: flex;
  flex-direction: column;
  padding: 3px;
}
.time-tooltip span {
  font-size: 14px;
  margin-top: 5px;
}

.time-tooltip-container {
  
  width: 250px;
  min-height: 100px;
  background: #333;
}

.--active-player {
  z-index: 100
}

.--inactive-player {
  z-index: 50;
}